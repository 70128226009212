import React, { useEffect, useState } from 'react';
import { CloudFilled, FileImageOutlined,DownloadOutlined } from '@ant-design/icons';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const FilePreview = ({ fileUrl, fileName }) => {
  const [hovered, setHovered] = useState(false);
  const [file, setfile] = useState('');
  const [imageSrc, setImageSrc] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  function blobToDataURL(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
  // useEffect(() => {
  //   let isMounted = true;
  //   const controller = new AbortController();

  //   const getFile = async () => {
  //     try {
  //       const response = await axiosPrivate.get(`/files/${fileUrl}`, {
  //         responseType: 'blob',
  //         signal: controller.signal,
  //       });

  //       const dataUrl = await blobToDataURL(response.data);
  //       setImageSrc(dataUrl);

  //       isMounted && setfile(response);
  //     } catch (err) {}
  //   };

  //   getFile();

  //   return () => {
  //     isMounted = false;
  //     controller.abort();
  //   };
  // }, []);

  const handleMouseEnter = () => {
    setHovered(true);
       let isMounted = true;
    const controller = new AbortController();

    const getFile = async () => {
      try {
        const response = await axiosPrivate.get(`/files/${fileUrl}`, {
          responseType: 'blob',
          signal: controller.signal,
        });

        const dataUrl = await blobToDataURL(response.data);
        setImageSrc(dataUrl);

        isMounted && setfile(response);
      } catch (err) {}
    };

    getFile();

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleDownload = () => {
    let isMounted = true;
    const controller = new AbortController();

    const getFile = async () => {
      try {
        const response = await axiosPrivate.get(`/files/${fileUrl}`, {
          responseType: 'blob',
          signal: controller.signal,
        });

        const dataUrl = await blobToDataURL(response.data);
        setImageSrc(dataUrl);

        isMounted && setfile(response);
        const blobUrl = URL.createObjectURL(file.data);
        const tempAnchor = document.createElement('a');
        tempAnchor.href = blobUrl;
        tempAnchor.download = fileName;
        tempAnchor.click();
     
        URL.revokeObjectURL(blobUrl);
      } catch (err) {}
    };
   
      getFile();

 
    return () => {
      isMounted = false;
      controller.abort();
    };



   

    // Perform the file download
    // window.open(fileUrl, '_blank');
  };

  return (
    <ul className="list-unstyled mb-1">
      <li
        onClick={handleDownload}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <abbr title={fileName} className='text-lowercase'>
          
         { /*<FileImageOutlined />*/}
          file.
          {fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2)}{' '}
          <DownloadOutlined className='float-right' />
        </abbr>
        {hovered && (
          <div className="file-preview">
            {imageSrc && (
              <img
                src={imageSrc}
                alt="File Preview Not Supported Click To Download"
              />
            )}
          </div>
        )}
      </li>
    </ul>
  );
};

export default FilePreview;
